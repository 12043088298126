import { Component, Vue } from 'vue-property-decorator'

//components
import Navbar from '@/components/navbar/Navbar.vue'
import ListPanel from '@/components/listPanel/ListPanel.vue'
import ListMenuOption from '@/components/listMenuOption/ListMenuOption.vue'
import ListGroups from '@/components/listGroups/ListGroups.vue'
import PrivateContent from '@/components/privateContent/PrivateContent.vue'
import HistoryProcess from '@/components/historyProcess/HistoryProcess.vue'

//classes
import { Alert } from '@/services/notification'

//models
import { Form, Input } from '@/components/wrappers/molds/Form';
import { User } from '@/molds/User'

//Vuex
const namespace = 'perfil';
import { Action, Getter } from 'vuex-class';

//wrappers
import {
	Container,
	Box,
	WTabs,
	WColumns,
	WColumn,
	WButton,
	WHr,
	WForm,
	WSubtitle,
	Loader,
} from '@/components/wrappers';

@Component({
	components: {
		Navbar,
		Container,
		Box,
		ListPanel,
		WTabs,
		WColumns,
		WColumn,
		ListMenuOption,
		WButton,
		WHr,
		ListGroups,
		PrivateContent,
		HistoryProcess,
		WForm,
		WSubtitle,
		Loader
	}
})
export default class Perfil extends Vue {

	@Action('fetchPerfil', { namespace }) fetchPerfil: any;
	@Action('updatePerfil', { namespace }) updatePerfil: any;
	@Action('updatePerfilPasswd', { namespace }) updatePerfilPasswd: any;
	@Getter('perfil', { namespace }) perfil!: User;
	@Getter('loader', { namespace }) isLoading!: boolean;
	@Getter('error', { namespace }) error!: any;

	alert = new Alert(this.$store)

	disableButton = false
	onFormRedefinePasswd = false
	loaderPasswd = false
	isDisabled = true

	editUser() {
		this.isDisabled = !this.isDisabled
	}

	mounted(): void {
		document.title = "Dados de Perfil"
		this.getPerfil()
	}

	async getPerfil() {
		await this.fetchPerfil()
		if (this.error) {
			this.alert.apiMessage("Não foi possível carregar os dados do perfil.", this.error, "danger")
		}
	}

	async dispatchUpdatePerfil(user: User): Promise<void> {
		
		await this.updatePerfil(user)

		if (this.error) {
			this.alert.apiMessage("Não foi possível atualizar dados.", this.error, "danger")
		} else {
			this.alert.success('Perfil atualizado com sucesso!')
			this.alert.addNotification({
				label: "Seus dados foram atualizados",
				confirm: true
			})
		}
		this.editUser()
	}

	async sendFormRedefinePasswd(passwds: any): Promise<void> {
		if (passwds.newpassword != passwds.confirmPassword) {
			this.alert.warning("Nova senha e confirmar senha não podem se diferentes")
		} else {
			this.loaderPasswd = true
			const data = {
				password: passwds.password,
				newpassword: passwds.newpassword
			}
			await this.updatePerfilPasswd(data)

			if (this.error) {
				this.alert.apiMessage("Não foi possível atualizada senha.", this.error, "danger")
			} else {
				this.alert.success("Senha atualizada com sucesso!")
				this.alert.addNotification({
					label: "Sua senha foi atualizada",
					confirm: true
				})
			}
			this.loaderPasswd = false
		}
	}

	get formPerfil(): Form {
		return {
			flex: false,
			indent: true,
			convertDate: false,
			inputsPadding: '10px',
			grid: this.grid
		}
	}

	get grid(): Input[][] {
		if(this.isLoading){
			return []
		}
		return [
			[
				{
					fieldName: "name",
					label: "Nome",
					type: "text",
					maxlength: 50,
					placeholder: "nome",
					required: true,
					value: this.perfil.name,
					disabled: this.isDisabled
				},
				{
					fieldName: "login",
					label: "E-mail",
					type: "email",
					maxlength: 50,
					placeholder: "email",
					required: true,
					value: this.perfil.login,
					disabled: this.isDisabled
				},
			],
			[
				{
					fieldName: "birthDate",
					label: "Nascimento",
					type: "date",
					maxlength: 10,
					placeholder: "",
					required: true,
					value: this.perfil.birthDate,
					disabled: this.isDisabled
				},
			],
		]
	}

	get redefinePasswd(): Form {
		return {
			flex: false,
			indent: true,
			convertDate: true,
			grid: [
				[
					{
						fieldName: "password",
						label: "Senha atual",
						type: "password",
						maxlength: 50,
						size: 30,
						minlength: 6,
						placeholder: "senha atual",
						required: true,
					},
				],
				[
					{
						fieldName: "newpassword",
						label: "Nova Senha",
						type: "password",
						maxlength: 50,
						size: 30,
						minlength: 6,
						placeholder: "Nova Senha",
						required: true,
					},
				],
				[
					{
						fieldName: "confirmPassword",
						label: "Confirmar Senha",
						type: "password",
						maxlength: 50,
						minlength: 6,
						size: 30,
						placeholder: "Confirmar Senha",
						required: true,
					},
				]
			]
		}
	}

}
